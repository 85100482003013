import { useNavigate } from "react-router-dom";
import { useRestaurant } from "../contexts/RestaurantContext";
import { getPersistableQueryString } from "../hooks/useQueryParams";
import Restaurant from "../models/Restaurant";

export const getRestaurantPath = (restaurant: Restaurant, path: string) => {
    let resultPath = path;

    if (!resultPath.includes('?')) {
        const queryParams = getPersistableQueryString();
        resultPath = `${resultPath}?${queryParams}`;
    }

    return resultPath;
}

export const getRestaurantFullUrl = (restaurant: Restaurant, path: string) => {
    return window.location.origin + getRestaurantPath(restaurant, path);
}

export const useRestaurantNavigate = () => {
    const restaurant = useRestaurant();
    const navigate = useNavigate();

    return (path: string) => navigate(getRestaurantPath(restaurant, path));
}

