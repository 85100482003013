import React from 'react'
import Product, { ProductForm } from '../models/Product'
import { EggSVG } from '../assets/svgs';

interface Props {
    product: Product | ProductForm
    className?: string
}

export default function ProductImg(props: Props) {
    const { product } = props
    return (
        <div className={`product-image ${props.className || ''} `}>
            {product.pictureUrl
                ? <img src={product.pictureUrl} alt={product.name} className="mw-100 mh-100" />
                : EggSVG
            }
        </div>
    )
}
