import Menu from '../../components/customer/Menu'
import CheckoutFooter from '../../components/customer/CheckoutFooter';
import { STAFF_NAV } from '../../constants/routes';
import { useCart } from '../../contexts/CartContext';
import Product from '../../models/Product';
import CompanyFooter from '../../components/customer/CompanyFooter';
import { useState } from 'react';
import { useProducts } from '../../contexts/ProductsContext';
import { useParams } from 'react-router-dom';
import ProductModal from '../../components/modals/ProductModal';
import ProductTile from '../../components/customer/ProductTile';
import { useRestaurantNavigate } from '../../utils/restaurant';

export default function ProductsPage() {
    const { productId } = useParams<{ productId: string }>();
    const productsHolder = useProducts();
    const { cart } = useCart();
    const [collapsedCategories, setCollapsedCategories] = useState<Record<string, boolean>>({})
    const chosenProduct = productsHolder.allProductsById[productId ?? ''];
    const navigate = useRestaurantNavigate();

    const toggleCategory = (category: string) => {
        setCollapsedCategories(prev => ({ ...prev, [category]: !prev[category] }))
    }


    const handleProductTitleClick = (product: Product) => {
        navigate(`/${STAFF_NAV.PRODUCTS}/${product.id}`);
    }

    return (
        <div className='streach'>
            <Menu noBack={true} />
            <div className="container">
                {productsHolder.categories?.map(category => {
                    const isCollapsed = collapsedCategories[category] ?? false;
                    return <div key={category}>
                        <div className='mt-3 d-flex pointer' onClick={() => toggleCategory(category)}>
                            <i className={`me-2 sbi  text-primary ${isCollapsed ? 'bi-caret-right-fill' : 'bi-caret-down-fill'}`} />
                            <h5 className='m-0 text-primary'>{category}</h5>
                        </div>
                        {!isCollapsed && <div className='d-flex flex-wrap'>
                            {productsHolder.productsByCategory[category]?.map(product =>
                                <ProductTile key={product.id} product={product} onClick={handleProductTitleClick} />)}

                        </div>}

                    </div>
                })}
            </div>

            {chosenProduct && <ProductModal product={chosenProduct} />}
            <CheckoutFooter cart={cart} />
            <CompanyFooter />
        </div>
    )
}
