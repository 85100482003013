import Menu from '../../components/customer/Menu'
import { CUSTOMER_NAV } from '../../constants/routes';
import { useText } from '../../contexts/TextContext'
import RestaurantLink from '../../components/RestaurantLink';
import CheckoutApplyCode from '../../components/customer/CheckoutApplyCode';
import { useCart } from '../../contexts/CartContext';
import CompanyFooter from '../../components/customer/CompanyFooter';
import CheckoutPayments from '../../components/customer/CheckoutPayments';
import CheckoutTip from '../../components/customer/CheckoutTip';
import BusyIndicator, { useQueueData } from '../../components/customer/BusyIndicator';
import SchedulePickup from '../../components/customer/SchedulePickup';
import LineItems from '../../components/customer/LineItems';


export default function CheckoutPage() {
    const { cart } = useCart();
    const text = useText();
    const { minutesToLatestOrder, ordersInQueue } = useQueueData();


    const isEmpty = !cart.items.length;
    if (isEmpty) {
        return <div className='streach'>
            <Menu />
            <div className="container">
                <div className="center flex-column pt-4 text-center text-primary">

                    <h2 >{text.cartEmptyText}</h2>
                    <div className="m-4" />
                    <RestaurantLink to={`/${CUSTOMER_NAV.PRODUCTS}`}>
                        <button className="btn btn-light">{text.products}</button>
                    </RestaurantLink>
                </div>
            </div>
        </div>
    }

    return (
        <div className='streach'>
            <Menu />
            <div className="container text-primary d-flex flex-column">
                <LineItems order={cart} />

                <div className='mt-5'>
                    <SchedulePickup className='mb-4' minutesToLatestOrder={minutesToLatestOrder} ordersInQueue={ordersInQueue} />
                    <div className='center justify-content-start mb-4'>
                        <span className='me-2'>{text.orderQueueWaitTime}</span>
                        <BusyIndicator showTime={true} minutesToLatestOrder={minutesToLatestOrder} />
                    </div>
                    <CheckoutTip className='mb-4' />
                    <CheckoutApplyCode className=' mb-4' />
                </div>

                <CheckoutPayments minutesToLatestOrder={minutesToLatestOrder} />

            </div>
            <CompanyFooter />
        </div>
    )
}
