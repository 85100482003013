import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { CUSTOMER_NAV, STAFF, STAFF_NAV, STAFF_SUBNAVIGATION } from './constants/routes';
import ProductsPage from './pages/customer/ProductsPage';
import CheckoutPage from './pages/customer/CheckoutPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmPage from './pages/customer/ConfirmPage';
import { AuthProvider } from './contexts/AuthContext';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './styles/App.scss';
import SaveToastContext from './contexts/SaveToastContext';
import RestaurantProvider from './contexts/RestaurantContext';
import SuperAdmin from './pages/SuperAdmin';
import TermsPage from './pages/TermsPage';
import CartProvider from './contexts/CartContext';
import PublicTerminalProvider from './contexts/PublicTerminalContext';
import PrivacyPage from './pages/PrivacyPage';
import SettingsProvider from './contexts/SettingsContext';
import TextProvider from './contexts/TextContext';
import Loading from './components/Loading';
import MyOrders from './pages/customer/MyOrders';
import MyOrder from './pages/customer/MyOrder';
import StaffDealEditPage from './pages/staff/StaffDealEditPage';
import MyBenefits from './pages/customer/MyBenefits';
import OfferPage from './pages/OfferPage';
import StaffAuditPage from './pages/staff/StaffAuditPage';
import LastOrderContext from './contexts/LastOrderContext';
import CardProcessingPage from './pages/customer/CardProcessingPage';
import RequireAuth from './components/RequireAuth';
import ProductsProvider from './contexts/ProductsContext';
import ConfirmProvider from './contexts/ConfirmContext';
const StaffRestaurantFeatures = lazy(() => import('./pages/staff/StaffRestaurantFeatures'));
const StaffDashboardPage = lazy(() => import('./pages/staff/StaffDashboardPage'));
const StaffProductsPage = lazy(() => import('./pages/staff/StaffProductsPage'));
const StaffProductDetailPage = lazy(() => import('./pages/staff/StaffProductDetailPage'));
const StaffOrderHistoryPage = lazy(() => import('./pages/staff/StaffOrderHistoryPage'));
const StaffRestaurantOpeningHoursPage = lazy(() => import('./pages/staff/StaffRestaurantOpeningHoursPage'));
const StaffRestaurantBranding = lazy(() => import('./pages/staff/StaffRestaurantBranding'));
const StaffOrdersAnalyticsPage = lazy(() => import('./pages/staff/StaffOrdersAnalyticsPage'));
const StaffDealsPage = lazy(() => import('./pages/staff/StaffDealsPage'));
const StaffRestaurantAccounting = lazy(() => import('./pages/staff/StaffRestaurantAccounting'));
const StaffRestaurantDevice = lazy(() => import('./pages/staff/StaffRestaurantDevice'));
const StaffOrderFeedbackPage = lazy(() => import('./pages/staff/StaffOrderFeedbackPage'));
const StaffOrderDayReports = lazy(() => import('./pages/staff/StaffOrderDayReports'));

function App() {
  return (
    <AuthProvider>
      <SettingsProvider>
        <TextProvider>
          <SaveToastContext>
            <Suspense fallback={<div><Loading /></div>}>
              <ConfirmProvider>
                <BrowserRouter>
                  <Routes>
                    <Route path='/superAdmin' element={<RequireAuth type='superAdmin'>
                      <SuperAdmin />
                    </RequireAuth>} />
                    <Route path={`/${STAFF}/*`} element={<StaffRestaurantApp />} />
                    <Route path={`/*`} element={<RestaurantApp />} />
                  </Routes>
                </BrowserRouter>
              </ConfirmProvider>
            </Suspense>
          </SaveToastContext>
          <ToastContainer />
        </TextProvider>
      </SettingsProvider>
    </AuthProvider >
  );
}

const StaffRestaurantApp = () => {
  return <RestaurantProvider>
    <ProductsProvider>

      <RequireAuth>

        <Routes>

          <Route path={`${STAFF_NAV.PRODUCTS}/:productId`} element={<StaffProductDetailPage />} />
          <Route path={`${STAFF_NAV.PRODUCTS}`} element={<StaffProductsPage />} />
          <Route path={`${STAFF_NAV.DEALS}/:dealId`} element={<StaffDealEditPage />} />
          <Route path={`${STAFF_NAV.DEALS}`} element={<StaffDealsPage />} />

          <Route path={`${STAFF_NAV.ORDERS}/${STAFF_SUBNAVIGATION.ORDERS_ANALYTICS}`} element={<StaffOrdersAnalyticsPage />} />
          <Route path={`${STAFF_NAV.ORDERS}/${STAFF_SUBNAVIGATION.ORDERS_FEEDBACK}`} element={<StaffOrderFeedbackPage />} />
          <Route path={`${STAFF_NAV.ORDERS}/${STAFF_SUBNAVIGATION.ORDERS_DAY_REPORTS}`} element={<StaffOrderDayReports />} />
          <Route path={`${STAFF_NAV.ORDERS}`} element={<StaffOrderHistoryPage />} />

          <Route path={`${STAFF_NAV.RESTAURANT}/${STAFF_SUBNAVIGATION.RESTAURANT_FEATURES}`} element={<StaffRestaurantFeatures />} />
          <Route path={`${STAFF_NAV.RESTAURANT}/${STAFF_SUBNAVIGATION.RESTAURANT_BRANDING}`} element={<StaffRestaurantBranding />} />
          <Route path={`${STAFF_NAV.RESTAURANT}/${STAFF_SUBNAVIGATION.RESTAURANT_DEVICE}`} element={<StaffRestaurantDevice />} />
          <Route path={`${STAFF_NAV.RESTAURANT}/${STAFF_SUBNAVIGATION.RESTAURANT_ACCOUNTING}`} element={<StaffRestaurantAccounting />} />
          <Route path={`${STAFF_NAV.RESTAURANT}`} element={<StaffRestaurantOpeningHoursPage />} />

          <Route path={`${STAFF_NAV.AUDIT_LOG}`} element={<StaffAuditPage />} />
          <Route path={`*`} element={<StaffDashboardPage />} />
        </Routes>
      </RequireAuth>
    </ProductsProvider>
  </RestaurantProvider>
}
const RestaurantApp = () => {

  return <RestaurantProvider>
    <ProductsProvider>

      <CartProvider>
        <PublicTerminalProvider>
          <LastOrderContext>
            <Routes>
              <Route path={`${CUSTOMER_NAV.PRODUCTS}/:productId`} element={<ProductsPage />} />
              <Route path={`${CUSTOMER_NAV.ORDER}`} element={<CheckoutPage />} />
              <Route path={`${CUSTOMER_NAV.CONFIRM}/:orderId`} element={<ConfirmPage />} />
              <Route path={`${CUSTOMER_NAV.CARD}/:orderId`} element={<CardProcessingPage />} />
              <Route path={`${CUSTOMER_NAV.TERMS}`} element={<TermsPage />} />
              <Route path={`${CUSTOMER_NAV.PRIVACY}`} element={<PrivacyPage />} />
              <Route path={`${CUSTOMER_NAV.OFFER}/:code`} element={<OfferPage />} />

              <Route path={`${CUSTOMER_NAV.MY_ORDERS}/:orderId`}
                element={<RequireAuth type='customer'><MyOrder /></RequireAuth>}
              />
              <Route path={`${CUSTOMER_NAV.MY_ORDERS}`}
                element={<RequireAuth type='customer'><MyOrders /></RequireAuth>}
              />
              <Route path={`${CUSTOMER_NAV.MY_BENEFITS}`}
                element={<RequireAuth type='customer'><MyBenefits /></RequireAuth>}
              />

              <Route path='*' element={<ProductsPage />} />
            </Routes>
          </LastOrderContext>
        </PublicTerminalProvider>
      </CartProvider>
    </ProductsProvider>
  </RestaurantProvider>
}

export default App;
