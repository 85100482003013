import { RawDraftContentState } from "draft-js";
import { parseAmount, printAmount } from "../utils/shared/converters";
import ProductOption, { ProductOptionForm, productOptionFromForm, productOptionToForm } from "./ProductOption";

const DEFAULT_VAT_RATE = 12;

interface Product {
    id: string,
    name: string,
    category: string,
    basePrice: number,
    pictureUrl?: string,
    state: ProductState,
    description?: RawDraftContentState,
    options: ProductOption[],
    color?: string,
    vatRate?: number,
    order?: number,
    tagline?: string,
    isUpsell?: boolean,
};

export default Product;

export const productToForm = (product: Product): ProductForm => {
    return {
        name: product.name,
        category: product.category,
        basePrice: printAmount(product.basePrice, { onlyAmount: true }),
        pictureUrl: product.pictureUrl || '',
        state: product.state,
        options: (product.options || []).map(productOptionToForm),
        color: product.color || '',
        vatRate: product.vatRate,
        tagline: product.tagline,
        isUpsell: product.isUpsell,
    };
};


export const productFromForm = (product: ProductForm): Product => {
    return {
        id: '',
        name: product.name,
        category: product.category || '',
        basePrice: parseAmount(product.basePrice),
        pictureUrl: product.pictureUrl || '',
        state: product.state,
        options: (product.options || []).map(productOptionFromForm),
        color: product.color.trim(),
        vatRate: product.vatRate || DEFAULT_VAT_RATE,
        tagline: product.tagline || '',
        isUpsell: product.isUpsell || false,
    };
};


export interface ProductForm {
    name: string,
    category: string,
    basePrice: string,
    pictureUrl: string,
    state: ProductState,
    options: ProductOptionForm[],
    color: string,
    tagline?: string,
    vatRate?: number,
    isUpsell?: boolean,
};


export enum ProductState {
    Active = 'active',
    Archived = 'archived',
}
