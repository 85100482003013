import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import CustomerReceiptForm from '../../components/customer/CustomerReceiptForm';
import FeedbackForm from '../../components/customer/FeedbackForm';
import LineItems from '../../components/customer/LineItems';
import Menu from '../../components/customer/Menu'
import OrderNumber from '../../components/customer/OrderNumber';
import Loading from '../../components/Loading';
import { CUSTOMER_NAV } from '../../constants/routes';
import { useDB } from '../../contexts/FirebaseContext';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { Order, OrderState } from '../../models/Order';
import { fromFirebaseDoc } from '../../utils/shared/firebase';

export default function MyOrder() {
    const db = useDB();
    const restaurant = useRestaurant();
    const [order, setOrder] = useState<Order | null>(null);
    const { orderId } = useParams<{ orderId: string }>();

    const orderState = order?.state || OrderState.NEW;
    const isLoading = orderState === OrderState.NEW;

    useEffect(() => {
        if (db && orderId && restaurant.id) {
            return onSnapshot(doc(db, "restaurants", restaurant.id, "orders", orderId), (doc) => {
                const newOrder = fromFirebaseDoc<Order>(doc)
                setOrder(newOrder);
            });
        }
    }, [db, orderId, restaurant.id]);

    if (!order || isLoading) {
        return <div className='streach'>
            <Menu />
            <div className='center'>
                <Loading />
            </div>
        </div>
    }


    return (
        <div className='streach'>
            <Menu back={`/${CUSTOMER_NAV.MY_ORDERS}`} />
            <div className='container text-primary'>
                <OrderNumber order={order} />

                <div>
                    <LineItems order={order} readOnly />
                </div>

                <FeedbackForm orderId={order.id || ''} />

                <CustomerReceiptForm order={order} />
            </div>
        </div >
    )
}
