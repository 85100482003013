import { useMemo, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useCart } from '../../contexts/CartContext'
import { useRestaurant } from '../../contexts/RestaurantContext'
import { useText } from '../../contexts/TextContext'
import { dateAtTime, dateTimeToString, formatTime, dateTimeToUnixSting } from '../../utils/shared/converters'
import "react-datepicker/dist/react-datepicker.css";
import { calculateCartTimeEstimateInMinutes, calculateOrderRemainingTimeEstimateInMinutes } from '../../utils/shared/cart'
import { useProducts } from '../../contexts/ProductsContext'
import { Order } from '../../models/Order'
import { isSameDate } from '../../utils/dates'

interface Props {
    minutesToLatestOrder: number,
    ordersInQueue: Order[],
    className?: string
}

interface TimeSlot {
    start: Date,
    disabled?: boolean
}

type ScheduleState = 'closed' | 'open';
const MAX_DAYS_IN_ADVANCE = 30 * 12;
const TIME_SLOT_INCREMENT = 15;

export default function SchedulePickup(props: Props) {
    const text = useText();
    const { cart, updatePickupDate } = useCart();
    const [state, setState] = useState<ScheduleState>('closed')
    const [choosenDay, setChosenDay] = useState<Date | null>(null);
    const [dayTime, setDayTime] = useState<string>('');
    const restaurant = useRestaurant();
    const { allProductsById } = useProducts();
    const pickupDate = cart.pickupDate;

    const cancel = () => {
        setChosenDay(null);
        if (state === 'closed') {
            updatePickupDate(null)
        } else {
            setState('closed')
        }
    }

    const pickSlot = (date: Date | null) => {
        if (date) {
            updatePickupDate(date);
            cancel();
        }
    }


    const dayTimeSlots = useMemo(() => {
        if (!choosenDay) {
            return [];
        }
        const timeSlots: TimeSlot[] = [];
        const dayDate = choosenDay.getDay();
        const openingHoursSegments = restaurant.openingHours?.[dayDate] || [];

        const orderTime = calculateCartTimeEstimateInMinutes(cart, restaurant, allProductsById)

        const now = new Date();
        const soonest = new Date();
        soonest.setMinutes(soonest.getMinutes() + orderTime + props.minutesToLatestOrder);

        const scheduledOrdersTimes = props.ordersInQueue
            .filter(order => isSameDate(order.pickupDate || null, choosenDay))
            .map(order => {
                const orderPrepTime = calculateOrderRemainingTimeEstimateInMinutes(order, restaurant, allProductsById)
                const endTime = new Date(order.pickupDate || 0)
                const startTime = new Date(endTime)
                startTime.setMinutes(startTime.getMinutes() - orderPrepTime)
                return { start: startTime, end: endTime }
            })


        for (const segment of openingHoursSegments) {
            let currentSlotFinishedByTime = dateAtTime(choosenDay, segment.start);
            currentSlotFinishedByTime.setSeconds(0);
            const endTime = dateAtTime(choosenDay, segment.end);
            currentSlotFinishedByTime.setMinutes(currentSlotFinishedByTime.getMinutes() + TIME_SLOT_INCREMENT);

            while (currentSlotFinishedByTime <= endTime) {
                const currentSlotEndTime = new Date(currentSlotFinishedByTime);
                currentSlotEndTime.setMinutes(currentSlotEndTime.getMinutes() + TIME_SLOT_INCREMENT);
                if (currentSlotFinishedByTime >= now) {
                    let disabled = currentSlotFinishedByTime < soonest;
                    for (const scheduledOrderTime of scheduledOrdersTimes) {
                        if (currentSlotFinishedByTime <= scheduledOrderTime.end && currentSlotFinishedByTime > scheduledOrderTime.start) {
                            disabled = true;
                            break;
                        }
                    }

                    timeSlots.push({
                        start: new Date(currentSlotFinishedByTime),
                        disabled,
                    });
                }
                currentSlotFinishedByTime = currentSlotEndTime;
            }
        }


        return timeSlots;
    }, [choosenDay, restaurant, cart, props.minutesToLatestOrder, allProductsById, props.ordersInQueue]);

    const startDate = new Date()
    const endDate = new Date()
    endDate.setDate(endDate.getDate() + MAX_DAYS_IN_ADVANCE);


    const renderTimeSlots = () => {
        if (!choosenDay) {
            return <></>
        }

        if (dayTimeSlots.length === 0) {
            return <div className='mt-4 center'>
                <h5>{text.noTimesAvailable}</h5>
            </div>
        }

        return <div className='mt-2'>
            <label>{text.chooseTime}</label>
            <div>
                <select value={dayTime}
                    onChange={(event) => setDayTime(event.target.value)
                    }
                    className="form-select"
                >
                    {dayTimeSlots.map(({ start, disabled }) => {
                        return <option key={dateTimeToUnixSting(start)} value={dateTimeToUnixSting(start)}
                            disabled={disabled}>
                            {formatTime(start)}
                        </option>
                    })}
                </select>
            </div>

            <button className='btn btn-outline-primary mt-4 w-100'
                onClick={() => pickSlot(dayTime ? new Date(dayTime) : dayTimeSlots[0].start)}>{text.confirm}</button>

        </div>
    }

    return (
        <div className={`${props.className}`}>
            <div className='center justify-content-start'>
                <div className='me-2'>{text.pickup}</div>
                <div className='bold me-3'>{pickupDate ? dateTimeToString(pickupDate) : text.asap}</div>
                <div className="btn-group" role="group" >
                    {state === 'closed' && <button className='btn btn-secondary' onClick={() => setState('open')}>
                        <i className="bi bi-calendar3" />
                        <div className='beta'>{text.beta}</div>
                    </button>}
                    {(state === 'open' || pickupDate) && <button className='btn btn-outline-secondary'
                        onClick={() => cancel()}>
                        <i className="bi bi-x" />
                    </button>}
                </div>
            </div>
            {state === 'open' && <div className='border mt-1 rounded p-2'>
                <label>{text.chooseDate}</label>
                <ReactDatePicker
                    className='form-control'
                    selected={choosenDay}
                    onChange={(date) => setChosenDay(date)}
                    minDate={startDate}
                    maxDate={endDate}
                />
                {renderTimeSlots()}
            </div>}
        </div >
    )
}
