import React from "react";
import ProductOption from "../../models/ProductOption"
import { printAmount } from "../../utils/shared/converters";
import { Button, ButtonGroup } from "react-bootstrap";

interface PropsMandatory {
    option: ProductOption,
    size?: 'sm',
    choosen: string[],
    handleChoice: (choiceId: string) => void,
}

export const ProductOptionInputMandatory = (props: PropsMandatory) => {
    const { option, choosen, handleChoice, size } = props;

    const isSmall = size === 'sm';

    return <div >
        <h5 className={`mt-3 ${isSmall && 'h6'}`}>{option.name}</h5>
        <ButtonGroup>

            {option.choices.map((choice, choiceIx) => {
                const isSelected = choosen.includes(choice.id);
                return <Button
                    size={isSmall ? 'sm' : undefined}
                    variant={isSelected ? 'primary' : 'outline-primary'}
                    disabled={choice.disabled}
                    key={choiceIx}
                    onClick={() => handleChoice(choice.id)}
                >
                    {choice.name} <span className="bold">{choice.priceAdjustment ? printAmount(choice.priceAdjustment) : ''}</span>
                </Button>
            })}
        </ButtonGroup>
    </div>
}


interface PropsOptional {
    option: ProductOption,
    choosen: string[],
    size?: 'sm',
    handleChoice: (choiceId: string, value: boolean) => void,
}


export const ProductOptionInputOptional = (props: PropsOptional) => {
    const { option, choosen, handleChoice } = props;
    const [isCollapsed, setIsCollapsed] = React.useState(true);
    const isSmall = props.size === 'sm';
    const renderOptions = () => {
        if (isCollapsed) {
            return null;
        }
        return option.choices.map((choice, choiceIx) => <div className="form-check" key={choiceIx}>
            <input className="form-check-input" type='checkbox' disabled={choice.disabled} checked={choosen.includes(choice.id)} onChange={(e) => handleChoice(choice.id, e.target.checked)} />
            <label className="form-check-label" >{choice.name} <span className="bold">{choice.priceAdjustment ? printAmount(choice.priceAdjustment) : ''}</span></label>
        </div>)
    }


    return <div >
        <h5 className={`mt-3 pointer ${isSmall && 'h6'}`} onClick={() => setIsCollapsed(!isCollapsed)}><i className={`me-2 sbi  text-primary ${isCollapsed ? 'bi-caret-right-fill' : 'bi-caret-down-fill'}`} /> {option.name}</h5>
        {renderOptions()}
    </div>
}