import React from 'react'
import Product from '../../models/Product'
import { printAmount } from '../../utils/shared/converters'
import ProductImg from '../ProductImg'

interface Props {
    product: Product
    onClick: (product: Product) => void
}

function ProductTile(props: Props) {
    const { product } = props

    return <div className="tile-product" key={product.id}>
        <div className="inner gap-0 pointer" onClick={() => props.onClick(product)}>
            <div className='flex-1 d-flex flex-column align-items-start justify-content-between'>
                <div>
                    <h4 className='fs-5'>{product.name}</h4>
                    <p className='mb-1 text-muted text-align-left'>{product.tagline}</p>

                </div>

                <p className='m-0'>{printAmount(product.basePrice)}</p>
            </div>
            <div className='flex-1 position-relative'>
                <ProductImg product={product} className='rounded overflow-hidden h-100' />

                <button className='btn btn-primary position-absolute' style={{ top: 0, right: 0 }}>
                    <i className='bi bi-plus fs-5' />
                </button>
            </div>
        </div>
    </div>

}
export default ProductTile
